<template>
  <div class="addvisit">
    <el-dialog title="添加访视记录" @close="closeDailog" :visible.sync="visible" width="800">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
        <el-form-item label="访视类型" prop="visitType">
          <span v-if="first">初诊</span>
          <el-radio-group v-else v-model="ruleForm.visitType">
            <el-radio v-for="item in typeList" :label="item.value" :key="item.value">{{item.name}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="访视日期" prop="visitTime">
          <el-date-picker
            style="width: 350px;"
            value-format="yyyy-MM-dd"
            v-model="ruleForm.visitTime"
            type="date"
            placeholder="请选择"
          ></el-date-picker>
        </el-form-item>
        <el-form-item style="margin-bottom: 5px !important;" label="访视表单" required>
			<el-container >
			
			   <el-aside style="border: 1px solid #EEEEEE;margin-right: 5%;" width="50%">
					<div class="qxzyfs">请选择要发送的内容</div>
					<el-input placeholder="请输入关键字查询" v-model="searchValue" style="width: 250px;height: 30px;margin-left: 20px;margin-bottom: 10px;" class="input-with-select">
					  <el-button slot="append" @click="gettreeDate()" style="height: 30px;" icon="el-icon-search"></el-button>
					</el-input>
						<OrgTree v-if="visible" ref="tree" @refreshDataList="getList" :content="content" :type='type' v-bind="$attrs"></OrgTree>
					</el-aside>
			 <el-aside width="45%" class='right' style=''>
			 	<div class="qxzyfs">已选择的内容</div>
				   <el-table
				     :data="itemList"
				     style="width: 100%"
				   >
				     <el-table-column prop="name" label="内容"></el-table-column>
			
				    <el-table-column width="100" label="操作" fixed="right" align="right" header-align="right">
				       <template slot-scope="scope">
				   
				       
						 <img @click="delContent(scope.row,scope.$index)" style="width: 14px;height: 14px;" src="../../../../assets/img/fadel.png" alt="">
				       </template>
				     </el-table-column>
				   </el-table>

					</el-aside>
			   </el-container>
         <!-- <el-form>
            <el-form-item>
              <div class="title">
                <div class="name">问卷类别</div>
                <div class="name">问卷名称</div>
              </div>
              <el-container style="height: 380px;width: 100%;">
                <el-aside width="50%" class="left" style="height: 100%">
                  <el-scrollbar class="custom-scrollbar" style="height: 100%" ref="scrollbar">
                    <OrgTree v-if="visible" @refreshDataList="getList" :type="type" v-bind="$attrs"></OrgTree>
                  </el-scrollbar>
                </el-aside>
                <el-aside width="50%" class="right" style="">
                
                  <el-scrollbar class="custom-scrollbar" style="height: 100%" ref="scrollbar">
                    <el-checkbox-group v-model="item">
                      <el-checkbox
                        @change="setCheck"
                        v-for="(item,index) in itemList"
                        :key="index"
                        :label="item.id"
                        :disabled="item.disabled"
                        style="height:28px;line-height:28px"
                      >{{item.name}}</el-checkbox>
                    </el-checkbox-group>
                  </el-scrollbar>
                </el-aside>
              </el-container>
            </el-form-item>
          </el-form> -->
        </el-form-item>
      </el-form>
       <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addVisitInfo">确 定</el-button>
        <el-button @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import OrgTree from './tree2/tree'
import Qs from "qs";
export default {
  name: "chooseQuestion",
  components: {
    OrgTree,
  },
  props: ["type"],
  data() {
    return {
		searchValue:'',
      first: false,
	  content:'',
      typeList: [],
      radio: 7,
      // 日期

      ruleForm: {
        visitType: "",
        visitTime: "",
        infoId: "",
      },
      rules: {
        visitTime: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        visitType: [
          { required: true, message: "请选择访视类型", trigger: "change" },
        ],
      },
      // radio:'',
      visible: false,
      options: [],
      props: {
        // emitPath:false,
        lazy: true,
        value: "value",
        label: "name",
        lazyLoad: async (node, resolve) => {
          // console.log(node.value,type);
          const { level } = node;
          // console.log(level);
          if (level === 1) {
            if (this.type == 5) {
              const { data: res } = await this.$http({
                url: this.$http.adornUrl("/indexLibrary/list"),
                method: "get",
                params: {
                  contentType: node.value,
                },
              });
              let list = [];
              if (res.data.length) {
                this.itemList = res.data;
                list = Array.from(res.data).map((v) => ({
                  value: v.id,
                  name: v.libraryName,
                  leaf: level >= 1,
                }));
              } else {
                list = [
                  {
                    value: 123,
                    name: "暂无数据",
                    leaf: level >= 1,
                    disabled: true,
                  },
                ];
              }
              // console.log(list,'list');
              // console.log(res,'load res ');
              resolve(list);
            } else {
              const { data: res } = await this.$http({
                url: this.$http.adornUrl("/missionaryWork/list"),
                method: "get",
                params: {
                  workType: node.value,
                },
              });
              let list = [];
              if (res.data.length) {
                this.itemList = res.data;
                list = Array.from(res.data).map((v) => ({
                  value: v.id,
                  name: v.workName,
                  leaf: level >= 1,
                }));
              } else {
                list = [
                  {
                    value: 123,
                    name: "暂无数据",
                    leaf: level >= 1,
                    disabled: true,
                  },
                ];
              }
              // console.log(list,'list');
              // console.log(res,'load res ');
              resolve(list);
            }
          }
        },
      },
      item: [],
      itemList: [], //懒加载的item列表
      initType: "",
      flag: 1,
    };
  },
  created() {
    this.initType = this.type;
    console.log(this.type, this.initType);
  },
  mounted() {
    if (this.first) {
      this.ruleForm.visitType = 0;
    }
    this.init();
    this.getDictList();
    this.getDataList();
  },

  methods: {
	  gettreeDate(){
	  	   this.content = this.searchValue
	  },
    getDataList() {
      let that = this;
      let teamsId = "";
      let doctorId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        doctorId = JSON.parse(localStorage.userInfo).id;
      }
      let params = {
        teamsId: teamsId,
        doctorId: doctorId,
        patienceId: JSON.parse(localStorage.patientInfo).patience_id,
      };

      that.dataListLoading = true;
      that
        .$http({
          url: that.$http.adornUrl("/visitInfo/list"),
          method: "get",
          params: params,
        })
        .then(({ data }) => {
          if (data.status) {
            if (data.data.length == 0) {
              console.log("that.dataList.length==0");
              that.first = true;
              this.ruleForm.visitType = 0;
            } else {
              console.log("that.dataList.length!=0");
              that.first = false;
            }
          }
        });
    },
    setCheck(val) {
      console.log(val);
    },
    closeDailog() {
      this.$emit("closeeQs");
      // this.visible= false
    },
    change() {
      console.log("change", this.item);
      this.item = [];
    },
    getParentNode(n, arr) {
      // console.log('getParentNode','n',n,arr,'arr');
      if (n.parent && n.parent.data.name) {
        // console.log(n.parent.data.name,'n.parent.data.name',arr,'arr');
        arr.unshift(n.parent.data.value);
        this.getParentNode(n.parent, arr);
      } else {
        return arr;
      }
    },
	delContent(item,index){
		this.itemList.splice(index,1)
		this.$refs.tree.setChecked(item)
	},
    async getList(checkedNodes,checkedKeys,halfCheckedNodes,halfCheckedKeys){
    
      let arr = []
      for (let s of checkedKeys.checkedNodes) {
      	if(s.children==undefined){
      		arr.push(s)
      	}
      }
  
      if(arr.length){
    

        this.itemList = arr
      }else{
        // this.itemList = [{value:123,name:'暂无数据',disabled:true}]
		 this.itemList = []
      }
  //     let hospitalId = "";
  //     if (localStorage.userInfo) {
  //       hospitalId = JSON.parse(localStorage.userInfo).deptPid;
  //     }
  //     let teamsId = "";
  //     let enertyId = "";
  //     if (localStorage.currentTeam) {
  //       teamsId = JSON.parse(localStorage.currentTeam).id;
  //       enertyId = JSON.parse(localStorage.currentTeam).entityId;
  //     }
  //     if (this.type == 5) {
  //       let type = "";
  //       let typeSec = "";
  //       if (d.type == "表单类别") {
  //         type = d.value;
  //         typeSec = "";
  //       } else {
  //         type = n.parent.data.value;
  //         typeSec = d.value;
  //       }
  //       const { data: res } = await this.$http({
  //         url: this.$http.adornUrl("/vfTableInfo/list"),
  //         method: "get",
  //         params: {
  //           tableModel: type,
  //           tableType: typeSec,
  //           tableBelongs: 1,
  //           publishStatus: 1,
  //           isUse: 1,
  //           pageSize: 999,
  //           pageNo: 1,
  //           teamId: teamsId,
  //         },
  //       });
  //       let list = [];
  //       if (res.data.length) {
  //         res.data.forEach((v) => {
  //           v.name = v.tableName;
  //         });
         
  //         this.itemList = res.data;

  //       } else {
  //         this.itemList = [{ value: 123, name: "暂无数据", disabled: true }];
  //       }
       
  //     } else {
  //       let type = "";
  //       let typeSec = "";
  //       let arr = ["", "", "", "", ""];
  //       if (d) {
  //         if (d.type == "宣教分类") {
           
  //           arr = [d.value, ""];
  //         } else {
  //        arr = [n.data.value];
  //           this.getParentNode(n, arr);
  //         }
  //       }
  //       const { data: res } = await this.$http({
  //         url: this.$http.adornUrl("/missionaryWork/list"),
  //         method: "get",
  //         params: {
  //           workType: arr[0],
  //           workTypeSec: arr[1],
  //           workTypeThree: arr[2],
  //           workTypeFour: arr[3],
  //           workTypeFive: arr[4],
  //         },
  //       });
  
  //       if (res.data.length) {
  //         res.data.forEach((v) => {
  //           v.name = v.workName;
  //         });
  //         this.itemList = res.data;
          
  //       } else {
  //         this.itemList = [{ value: 123, name: "暂无数据", disabled: true }];
  //       }
  //     }
    },
    // 获取来源列表
    getDictList() {
      let _this = this;
      _this
        .$http({
          url: _this.$http.adornUrl("/dict/list?type=访视类型"),
          method: "get",
        })
        .then(({ data }) => {
          _this.typeList = data.data;
        });
    },
    // async getType(dict){
    //   const {data} = await this.$http({
    //     url:this.$http.adornUrl('dict/getDictList'),
    //     method:'get',
    //     params:{
    //       type:dict,
    //       pageSize:999
    //     }
    //   })
    //   for (var i = 0; i < data.data.length; i++) {
    // 				// data.data[i].isEdit = false;
    // 				data.data[i].itemList = [];

    // 			}
    //   // console.log(res,'res')
    //   for (var i = 0; i < data.data.length; i++) {
    // 				if(data.data[i+1]&&data.data[i].type==data.data[i+1].type){
    // 					console.log(data.data[i].type,data.data[i].name);
    // 					if(data.data[i].type==data.data[i].name){
    // 						data.data[i].itemList.push(data.data[i+1])
    // 						data.data.splice(i+1,1)
    // 					}else{
    // 						data.data[i+1].itemList= []
    // 						// console.log(data.data[i+1],'before');

    // 						data.data[i+1].itemList.push(data.data[i])
    // 						// console.log(data.data[i+1],'after');
    // 						data.data.splice(i,1)

    // 					}
    // 				}

    // 			}
    //       console.log(data.data,'data.data');
    //   this.options=data.data
    // },

    addVisitInfo() {
      let params = this.ruleForm;
	
      if (this.itemList.length == 0) {
        this.$message.error("请选择问卷！");
        return;
      }
	  let arr = []
	  this.itemList.forEach(item=>{
		  arr.push(item.id)
	  })
      params.infoId = arr.join(",");
      params.teamsId = JSON.parse(localStorage.currentTeam).id;
      params.patienceId = JSON.parse(localStorage.patientInfo).patience_id;
      params.doctorId = JSON.parse(localStorage.userInfo).id;

      let _this = this;

      _this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (_this.flag == 1) {
            _this.flag = 0;
            _this
              .$http({
                url: _this.$http.adornUrl("/visitInfo/add"),
                method: "post",
                data: Qs.stringify(params),
              })
              .then((data) => {
                if (data.data.status) {
                  _this.$message({
                    message: data.data.msg,
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      _this.closeDailog();
                      _this.flag = 1;
                    },
                  });
                } else {
                  _this.flag = 1;
                  _this.$message({
                    message: data.data.msg,
                    type: "error",
                    duration: 1500,
                    onClose: () => {},
                  });
                }
              });
          }
        }
      });
    },
    async init() {
      this.visible = true;
      console.log(this.type, "init type");
      let type = this.type;
      let dict = "";
      // if(type==2){
      //   type=4
      // }
      // if(type==1){
      //   type=5
      // }
      if (type === 5) {
        dict = "表单分类";
      } else {
        dict = "宣教分类";
      }
      // await this.getType(dict)
    },
    async confirm() {
      console.log(this.item, this.item[1]);
      let item = this.itemList.filter((v) => v.id == this.item)[0];
      console.log(item, "item");
      if (item) {
        // let obj = {type:this.options.find(v=>v.value==this.item[0]),item:this.itemList.find(v=>v.id===this.item[1])}
        //
        // console.log(obj);
        this.$emit("chooseQs", item);
        this.visible = false;
      } else {
        this.$message.error("请选择！");
      }
    },
  },
  // watch:{
  //   'this.type':function(value){
  //     console.log(value,'value watch');
  //     this.initType = value
  //   }
  // }
};
</script>

<style lang="scss" >
.addvisit {
  .el-dialog {
   width: 960px !important;
   height: 660px !important;
   margin-top: 10vh !important;
  }
 .el-dialog__footer{
 	border: 0 !important;
 	padding-bottom:0;
	padding-top:10px;
 }
  .qxzyfs{
  height: 20px;
  	font-size: 16px;
  	font-family: PingFangSC-Regular, PingFang SC;
  	font-weight: 400;
  	color: #333333;
  	line-height: 20px;
  	margin: 19px;
  }
  .el-dialog__body{
	  padding: 0px 20px;
  }
  /deep/.el-cascader-menu {
    width: 50%;
  }
  .title {
    width: 100%;
    display: flex;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border: 1px solid #cccccc;
    .name {
      width: 50%;
    }
  }
  .itemList {
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
  }
  .left {
    border: 1px solid #ccc;
    border-top: 0;
    overflow: auto;
    // scrollbar-width: none;
    // -ms-overflow-style: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
  .right{
  	  height: 410px;
    // border:1px solid #ccc;border-left:none;
  	border: 1px solid #EEEEEE !important;
  
  }
}
</style>