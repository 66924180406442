<template>
	<div class="fsexpand">
		<div>
			<div @click="handleFsAdd" class="addBtn">
			<img class="img" src="../../../../assets/img/add.png" alt="">
			 添加访视</div>
			<!-- <el-button @click="handleFsAdd" type="primary" class="addBtn">添加</el-button> -->
			<div class="tipArea">
				<div class="icon1"></div>
				<div class="text">已代填</div>
				<div class="icon2"></div>
				<div class="text">未填写</div>
				<div class="icon3"></div>
				<div class="text">已提交</div>
			</div>
			<el-tree ref="expandMenuList" class="expand-tree2" accordion v-if="isLoadingTree" :data="setTree" node-key="uuid"
				highlight-current :props="defaultProps" :expand-on-click-node="true" :render-content="renderContent"
				:default-expanded-keys="defaultExpandKeys"  @node-click="handleNodeClick"></el-tree>
				
		</div>
	</div>
</template>
<!-- VUE饿了么树形控件添加增删改功能按钮 -->
<script>
	import Qs from "qs";
	import TreeRender from "./tree_render";
	import api from "@/resource/api";
	import {
		treeDataTranslate
	} from "@/utils";
	export default {
		name: "tree",
		data() {
			return {
				first: "",
				//         maxexpandId: api.maxexpandId,//新增节点开始id
				//         non_maxexpandId: api.maxexpandId,//新增节点开始id(不更改)
				maxexpandId: "", //新增节点开始id
				non_maxexpandId: "", //新增节点开始id(不更改)
				num: "", //排序
				isLoadingTree: true, //是否加载节点树
				setTree: [], //节点树数据
				clientHeight: `${document.documentElement.clientHeight}` - 50 - 30 - 2 - 80,
				defaultProps: {
					children: "children",
					label: "fullName"
				},
				defaultExpandKeys: [], //默认展开节点列表
				currentId: "" //当前点击树id
			};
		},
		mounted() {
			// this.initExpand();
			this.getDeptList();
		},
		activated() {},
		methods: {
			initExpand() {
				this.setTree.map(a => {
					this.defaultExpandKeys.push(a.id);
				});
				this.isLoadingTree = true;
			},
			getDataList() {
				let that = this;
				let teamsId = "";
				let doctorId = "";
				if (localStorage.currentTeam) {
					teamsId = JSON.parse(localStorage.currentTeam).id;
					doctorId = JSON.parse(localStorage.userInfo).id;
				}
				let params = {

					teamsId: teamsId,
					doctorId: doctorId,
					patienceId: JSON.parse(localStorage.patientInfo).patience_id
				};

				that.dataListLoading = true;
				that
					.$http({
						url: that.$http.adornUrl("/visitInfo/list"),
						method: "get",
						params: params
					})
					.then(({
						data
					}) => {
						if (data.status) {

							that.dataList = data.data;
							that.oldList = data.data;
							that.dataListLoading = false;
							that.totalPage = data.totalCount;
						}
					});
			},
			// 获取部门列表
			getDeptList(id) {
				let that = this;
				let teamsId = "";
				let doctorId = "";
				if (localStorage.currentTeam) {
					teamsId = JSON.parse(localStorage.currentTeam).id;
					doctorId = JSON.parse(localStorage.userInfo).id;
				}
				this.$http({
					url: this.$http.adornUrl("/visitInfo/list"),
					method: "get",
					params: {
						teamsId: teamsId,
						doctorId: doctorId,
						patienceId: JSON.parse(localStorage.patientInfo).patience_id
					}
					// data: Qs.stringify(obj)
				}).then(({
					data
				}) => {
					console.log(data,'data');
					// let tempArr = [];
					if (data.data.length) {
						console.log('===============',data.data[0].visitInfos[0])

						if (data.data[0] && data.data[0].visitInfos.length) {
							this.$emit('showQs',data.data[0].visitInfos[0])
						}
						
					}
					for (var i = 0; i < data.data.length; i++) {
						data.data[i].isEdit = false;
						//如果随访相同则合并
					if(data.data[i].visitType==3){
						// if(!tempArr.length){
						// 		tempArr.push(data.data[i])
						// 	}else{
						// 	tempArr[0].visitInfos.push(...data.data[i].visitInfos)

						// }
						}
						if( data.data[i].visitTypeName){
							data.data[i].name = data.data[i].visitTypeName+'  ('+data.data[i].visitTime+')'
						}else{
							data.data[i].name ='初诊'+'  ('+data.data[i].visitTime+')'
						}
						
						for (var j = 0; j < data.data[i].visitInfos.length; j++) {
							data.data[i].visitInfos[j].name = data.data[i].visitInfos[j].tableName
						}

						data.data[i].children = data.data[i].visitInfos;
					}
					// data.data = data.data.filter(v=>v.visitType!==3)
					// data.data.push(...tempArr)
					// console.log(tempArr,'tempArr');
					this.setTree = data.data;
					this.defaultExpandKeys=[]
					this.defaultExpandKeys.push(this.setTree[0].uuid)
					console.log(this.setTree);
					this.maxexpandId = data.data[data.data.length - 1].id;
					this.non_maxexpandId = data.data[data.data.length - 1].id;
					this.num = data.data[data.data.length - 1].num;
					
					// console.log(this.setTree,'setTree')
					// this.defaultExpandKeys = id ? [id] : ["303868"];
				});
			},

			handleNodeClick(d, n, s) {
				//点击节点
				n.expanded = true
				d.isEdit = false; //放弃编辑状态
				this.$emit("refreshDataList", d, n);
				this.currentId = d.id;
			},
			renderContent(h, {
				node,
				data,
				store
			}) {
				//加载节点
				let that = this;
				return h(TreeRender, {
					props: {
						DATA: data,
						NODE: node,
						STORE: store,
						maxexpandId: that.non_maxexpandId,
						currentId: this.currentId || 1
					},
					on: {
						nodeAdd: (s, d, n) => that.handleAdd(s, d, n),
						nodeEdit: (s, d, n) => that.handleEdit(s, d, n),
						nodeShow: (s, d, n) => that.handleShow(s, d, n),
						nodeDel: (s, d, n) => that.handleDelete(s, d, n),
						nodeSend:(s, d, n) => that.handleSend(s, d, n),
					}
				});
			},
			handleFsAdd(){
				this.$emit('handleAdd')
			},
			handleAdd(s, d, n) {
				//增加节点
				console.log("1");
				// if(n.level >=2){
				//   this.$message.error("最多只支持二级！")
				//   return false;
				// }
				//添加数据
				// let pidStr = ''
				// if(d.pids==''){
				// 	pidStr=d.id
				// }else
				// {
				// 	pidStr=d.pids + ',' + d.id
				// }
				let number = n.childNodes.length + 1;
				let name = d.name + "-" + number;
				let type = n.level == 1 ? "表单类别" : d.name;
				let classify = n.level == 1 ? "表单类别" : d.name;
				console.log(classify, "classify");
				let user = JSON.stringify(localStorage.getItem("userInfo"));
				d.children.push({
					id: "",
					num: ++this.num,
					simpleName: name,
					pid: d.id,
					pids: d.id,
					isEdit: false,
					children: []
				});
				//展开节点
				if (!n.expanded) {
					n.expanded = true;
				}
				let Dept = {
					// 'userId':this.$cookie.get('userId'),
					// 'num': this.num,
					// 'pid': d.id,
					// pids: d.id ,
					// "simpleName": "二级部门",
					// "fullName": "二级部门",
					// "orderWeight": 0,
					status: 1,
					// "version": '',
					teamsId: JSON.parse(localStorage.currentTeam).id,
					type: type,
					name: name,
					createName: user.name,
					classify: "表单类别"
				};
				this.$http({
					url: this.$http.adornUrl("/dict/insert"),
					method: "post",
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",

						token: this.$cookie.get("token")
					},
					data: Qs.stringify(Dept)
				}).then(({
					data
				}) => {
					if (data.status) {
						this.getDeptList(n.data.id);
					}
				});
			},
			handleEdit(s, d, n) {
				//编辑节点
				
				
				console.log("触发编辑", s,d, n);
					this.$emit("handleEdit", d, n);
		
			},
handleShow(s, d, n) {
				//编辑节点
				
				n.expanded = !n.expanded
				console.log("触发编辑", s,d, n);
					this.$emit("showQs", d, n);
		
			},
			
			async handleDelete(s, d, n) {
			  this.$confirm("确定进行[删除]操作", "提示", {
			    confirmButtonText: "确定",cancelButtonClass: "btn-custom-cancel",
			    cancelButtonText: "取消",
			    type: "warning"
			  })
			    .then(async () => {
			    
			    
			 
			      const { data: res } = await this.$http({
			        url: this.$http.adornUrl(`/visitInfo/delete?uuid=${d.uuid}`),
			        method: "post",
						  // data:{
							//   uuid:d.uuid
						  // }
			      });
			      if (res.status) {
			        this.$message({
			          message: res.msg,
			          type: "success",
			          duration: 1500,
			          onClose: () => {
			            this.getDeptList();
			          }
			        });
			      }
			    })
			    .catch(() => {});
			
			},

			// 手动发送按钮
			async handleSend(s, d, n) {
			  this.$confirm("确定进行[发送]操作", "提示", {
			    confirmButtonText: "确定",cancelButtonClass: "btn-custom-cancel",
			    cancelButtonText: "取消",
			    type: "warning"
			  })
			    .then(async () => {
			    
			    console.log('s',s);
					console.log('d',d);
					console.log('n',n);
			 		const params = {
								infoId:d.infoId,
								patientId:d.patienceId,
								fillStatus:d.fillStatus,
								taskId:d.taskId||d.id,
								teamId:d.teamsId,
								type:3
					}
			      const { data: res } = await this.$http({
			        url: this.$http.adornUrl('/vfRelationInfo/add'),
			        method: "post",
						  data:Qs.stringify(params)
			      });
			      if (res.status) {
			        this.$message({
			          message: res.msg,
			          type: "success",
			          duration: 1500,
			          onClose: () => {
			            this.getDeptList();
			          }
			        });
			      }
			    })
			    .catch(() => {});
			
			}
		}
	};
</script>

<style   lang="scss">
	.fsexpand {
		width: 319px;
	}

	.addBtn {
		display: flex;
		align-items: center;
		width: 31.8rem;
		height: 25px;
		cursor: pointer;
		line-height: 25px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		background: #009CFF;
		border-radius: 2px;
		justify-content: center;
		margin-left: 8px;
		.img{
			width: 16px;
			height: 16px;
			margin-right: 8px;
		}
		
	}

	.tipArea {
		width: 319px;
		height: 33px;
		line-height: 33px;
		display: flex;
		justify-content: center;
		align-items: center;
border-bottom: 1px dotted #E9E9E9;
		.icon1 {
			border-radius: 50px;
			width: 6px;
			height: 6px;
			background: #009CFF;
			margin-right: 6px;
		}

		.icon2 {
				border-radius: 50px;
			width: 6px;
			height: 6px;
			background: #F61111;
			margin-right: 6px;
		}

		.icon3 {
				border-radius: 50px;
			width: 6px;
			height: 6px;
			background: #10CD30;
			margin-right: 6px;
		}

		.text {
			margin-right: 20px;
			height: 17px;
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			line-height: 17px;
		}
	}

	.expand>div {
		height: 100%;
		/*  padding-top:20px;
  width:50%;
  margin:20px auto; */
		/* margin-right: 10px; */
		border-bottom: 1px solid #EEEEEE;
		;
		border-right: 1px solid #EEEEEE;
		;
		/* max-width: 400px; */
		overflow-y: auto;
	}

	.expand>div::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 5px;
	}

	.expand>div::-webkit-scrollbar-thumb {
		background-color: rgba(50, 65, 87, 0.5);
		outline: 1px solid slategrey;
		border-radius: 5px;
	}

	.expand>div::-webkit-scrollbar {
		width: 10px;
	}

	.expand-tree2 {
		height: 435px;
		overflow: auto;
		border: none;
		margin-top: 10px;
		font-size: 17px;
	}

	.expand-tree2 .el-tree-node.is-current,
	.expand-tree2 .el-tree-node:hover {
		overflow: hidden;
	}

	.el-tree-node__content {
		height: 28px;
	}

	.expand-tree2 .is-current>.el-tree-node__content .tree-btn,
	.expand-tree2 .el-tree-node__content:hover .tree-btn {
		display: inline-block;
	}

	.expand-tree2 .is-current>.el-tree-node__content .tree-label {
		white-space: normal;
	}
</style>
